import React from 'react'

import { FormattedMessage } from 'react-intl'

import {
    LOW,
    MODERATE,
    CONSIDERABLE,
    HIGH,
    EXTREME,
} from '@avcan/constants/products/forecast/ratings'
import RatingStyles from 'styles/forecasts/ratings.module.css'
import { useUserSettings } from 'contexts/usersettings'

// regionId is currently only used by the consumer of this component to trigger a re-render
export const ForecastTooltip = ({ coordinates, dangerRating, regionId }) => {
    const { x, y } = coordinates
    const { colourblindModeEnabled } = useUserSettings()
    const ratings = [
        {
            rating: LOW,
            shortHeading: <FormattedMessage defaultMessage="1" description="Map tooltip" />,
            longHeading: <FormattedMessage defaultMessage="1 - Low" description="Map tooltip" />,
            message: (
                <FormattedMessage
                    defaultMessage="Watch for unstable snow on isolated terrain features"
                    description="Map tooltip"
                />
            ),
        },
        {
            rating: MODERATE,
            shortHeading: <FormattedMessage defaultMessage="2" description="Map tooltip" />,
            longHeading: (
                <FormattedMessage defaultMessage="2 - Moderate" description="Map tooltip" />
            ),
            message: (
                <FormattedMessage
                    defaultMessage="<b>Heightened</b> avalanche conditions on specific terrain features"
                    values={{
                        b: chunks => <strong>{chunks}</strong>,
                    }}
                    description="Map tooltip"
                />
            ),
        },
        {
            rating: CONSIDERABLE,
            shortHeading: <FormattedMessage defaultMessage="3" description="Map tooltip" />,
            longHeading: (
                <FormattedMessage defaultMessage="3 - Considerable" description="Map tooltip" />
            ),
            message: (
                <FormattedMessage
                    defaultMessage="<b>Dangerous</b> avalanche conditions"
                    values={{
                        b: chunks => <strong>{chunks}</strong>,
                    }}
                    description="Map tooltip"
                />
            ),
        },
        {
            rating: HIGH,
            shortHeading: <FormattedMessage defaultMessage="4" description="Map tooltip" />,
            longHeading: <FormattedMessage defaultMessage="4 - High" description="Map tooltip" />,
            message: (
                <FormattedMessage
                    defaultMessage="<b>Very dangerous</b> avalanche conditions"
                    values={{
                        b: chunks => <strong>{chunks}</strong>,
                    }}
                    description="Map tooltip"
                />
            ),
        },
        {
            rating: EXTREME,
            shortHeading: <FormattedMessage defaultMessage="5" description="Map tooltip" />,
            longHeading: (
                <FormattedMessage defaultMessage="5 - Extreme" description="Map tooltip" />
            ),
            message: (
                <FormattedMessage
                    defaultMessage="<b>Avoid all avalanche terrain</b>"
                    values={{
                        b: chunks => <strong>{chunks}</strong>,
                    }}
                    description="Map tooltip"
                />
            ),
        },
    ]

    if (!dangerRating) {
        return null
    }

    return (
        x &&
        y && (
            <div style={{ ...styles.tooltip, left: x + 'px', top: y + 'px' }}>
                <div style={styles.header}>
                    {ratings.map(({ rating, shortHeading, longHeading }) => {
                        const currentRating = rating === dangerRating
                        const className = colourblindModeEnabled
                            ? RatingStyles[rating + '--colourblind']
                            : RatingStyles[rating]

                        return (
                            <div
                                className={className}
                                key={rating}
                                style={{
                                    ...styles.rating,
                                    flexGrow: currentRating ? '5' : '1',
                                    fontWeight: currentRating ? 'bold' : 'inherit',
                                }}>
                                {currentRating ? longHeading : shortHeading}
                            </div>
                        )
                    })}
                </div>
                <div style={styles.body}>
                    {ratings.find(rating => {
                        return rating.rating === dangerRating
                    })?.message || (
                        <FormattedMessage
                            defaultMessage="No danger rating available"
                            description="Map tooltip"
                        />
                    )}
                </div>
            </div>
        )
    )
}

const styles = {
    tooltip: {
        position: 'absolute',
        transform: 'translate(-50%, -120%)',
        backgroundColor: 'white',
        width: '360px',
        padding: '8px',
        borderRadius: '8px',
        boxShadow: '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25)',
        overflow: 'auto',
    },
    rating: {
        padding: '0.3em 1em',
        fontSize: '0.8em',
        textAlign: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'stretch',
        marginBottom: '0.5em',
    },
    body: {
        textAlign: 'center',
        fontSize: '0.8em',
    },
}
