import * as React from 'react'

import * as Products from '@avcan/constants/products'
import * as ObservationTypes from '@avcan/constants/products/min/types'
import MapStyles from '@avcan/constants/map/styles'
import { COLOUR_BLIND_PALETTE } from '@avcan/constants/products/forecast/ratings/colors'
import { createMarkerFeatureCollection } from '@avcan/utils/products/forecasts/metadata'

import { useLayer as useLayerState } from 'contexts/layers'
import * as MapState from 'contexts/map/state'
import { useSecondaryDrawer } from './drawers/hooks'
import * as Sources from './sources/context'
import * as MapContext from 'contexts/map'
import { TILESETS } from 'services/mapbox/config'
import { ForecastTooltip } from './ForecastTooltip'
import { useUserSettings } from 'contexts/usersettings'
import './Map.module.css'
import { useReturnFocusId, useSetReturnFocusId } from 'stores/MapStore'
import { iceClimbs } from '@avcan/constants/products/ice-climbs'

const NULL_COORDINATES = { x: null, y: null }

export function ForecastAreas({ on, id }) {
    const hoveredRegion = React.useRef(null)
    const coordinates = React.useRef(NULL_COORDINATES)
    const regionIds = React.useRef([])
    const key = Products.FORECAST
    const map = MapContext.useMap()
    const areas = Sources.useForecastAreas()
    const metadata = Sources.useForecastMetadata()
    const [hoverRegionDangerRating, setHoverRegionDangerRating] = React.useState(null)
    const [hoverRegionId, setHoverRegionId] = React.useState(null)
    const { colourblindModeEnabled, hoverOverDangerRatingEnabled } = useUserSettings()

    // Inject metadata into each area's properties so that we can use them on the map
    const data = {
        ...areas,
        features: areas.features.map(area => {
            const specificMetaData = metadata.find(product => product.area.id === area.id)
            const { value: highestDanger } = specificMetaData.highestDanger
            const colour = colourblindModeEnabled
                ? COLOUR_BLIND_PALETTE.get(highestDanger)
                : specificMetaData.highestDanger.colour

            return {
                ...area,
                properties: {
                    ...area.properties,
                    colour,
                    id: specificMetaData.product.id,
                    highestDanger,
                },
            }
        }),
    }

    const { visible } = useLayerState(key)
    const product = Products.FORECAST

    const layers = [
        createLayer(key, key, 'fill'),
        createLayer(key + '-line', key, 'line'),
        createLayer(key + '-labels', key, 'symbol'),
    ]

    React.useEffect(() => {
        const source = key
        const find = area => area.properties.id === id

        if (!areas.features.some(find)) {
            return
        }

        const [feature] = map.querySourceFeatures(source, {
            filter: ['==', ['get', 'id'], id],
        })

        if (!feature) {
            return
        }

        const target = { source, id: feature.id }

        map.setFeatureState(target, { active: true })

        return () => {
            try {
                map.removeFeatureState(target, 'active')
            } catch {
                // Cam: This is here to prevent an error that displays when clicking on the fullscreen button from the map view of a forecast
                // Here is what copilot thinks it is:
                // This error occurs when the map is destroyed before the effect is cleaned up
            }
        }
    }, [product, id])

    const addHover = e => {
        const { features } = e

        // get all feature states for the source
        if (features.length > 0) {
            regionIds.current.forEach(id => {
                if (id !== features[0].properties.id) {
                    map.setFeatureState(
                        {
                            source: key,
                            id,
                        },
                        { hover: false }
                    )
                }
            })

            // add id to regionIds if its not already there
            if (!regionIds.current.includes(features[0].properties.id)) {
                regionIds.current.push(features[0].properties.id)
            }

            if (!features[0].state.hover) {
                hoveredRegion.current = features[0].properties.id
                map.setFeatureState(
                    {
                        source: key,
                        id: hoveredRegion.current,
                    },
                    { hover: true }
                )

                // These state changes result in browser performace reduction when hovering over forecast areas
                // with that context, if the user has disabled this feature, we don't want to update the state
                if (hoverOverDangerRatingEnabled) {
                    coordinates.current = e.point
                    setHoverRegionDangerRating(features[0].properties.highestDanger)
                    setHoverRegionId(features[0].properties.id)
                }
            }
        } else {
            map.setFeatureState(
                {
                    source: key,
                    id: hoveredRegion.current,
                },
                { hover: true }
            )
        }
    }

    const removeHover = () => {
        // clear all hover states
        regionIds.current.forEach(id => {
            map.setFeatureState(
                {
                    source: key,
                    id,
                },
                { hover: false }
            )
        })

        // These state changes result in browser performace reduction when hovering over forecast areas
        // with that context, so if the user has disabled this feature, we don't want to update the state
        if (hoverOverDangerRatingEnabled) {
            setHoverRegionDangerRating(null)
            coordinates.current = NULL_COORDINATES
            setHoverRegionId(null)
        }
    }

    // Event handlers
    map.on('mousemove', key, addHover)
    map.on('mouseleave', key, removeHover)

    return (
        <>
            <MapContext.Source id={key} data={data} generateId>
                {layers.map(layer => (
                    <MapContext.Layer key={layer.id} {...layer} visible={visible} on={on} />
                ))}
            </MapContext.Source>
            {hoverOverDangerRatingEnabled && (
                <ForecastTooltip
                    coordinates={coordinates.current}
                    dangerRating={hoverRegionDangerRating}
                    regionId={hoverRegionId}
                />
            )}
        </>
    )
}

export function ForecastMarkers() {
    const metadata = Sources.useForecastMetadata()
    const { features } = createMarkerFeatureCollection(metadata)

    return features.map(feature => {
        const { id, properties, geometry } = feature

        return <ForecastMarker key={id} lnglat={geometry.coordinates} {...properties} id={id} />
    })
}

function ForecastMarker({ lnglat, product, slug, title, id }) {
    const { visible } = useLayerState(product)
    const { setProductId, openPrimaryDrawer } = MapState.useMapState()
    const map = MapContext.useMap()
    const returnFocusId = useReturnFocusId()
    const setReturnFocusId = useSetReturnFocusId()
    // Keep track of whether the marker was clicked or if a mouse was used
    const [forecastMarkerClicked, setForecastMarkerClicked] = React.useState(false)
    const markerRef = React.useRef(null)

    React.useEffect(() => {
        if (returnFocusId === id) {
            setReturnFocusId(null)
            setProductId(null)

            if (forecastMarkerClicked) {
                setForecastMarkerClicked(false)
                setTimeout(() => {
                    // If the currentMapCenter and the lnglat are the same, then we are likely using keyboard instead of mouse
                    // Therefore, we want to focus on the marker
                    markerRef.current.focus({ focusVisible: true })
                    map.setFeatureState(
                        {
                            source: 'forecast',
                            id: id,
                        },
                        { hover: true }
                    )
                }, 200)
            }
        }
    }, [returnFocusId, setReturnFocusId, id, lnglat, map, setProductId])

    const handleMarkerClick = (e, id) => {
        e.stopPropagation()

        setProductId(id)
        openPrimaryDrawer()
        setForecastMarkerClicked(true)

        // pause (if required) to make sure we can select the drawer
        const drawer = document.getElementById('primary-drawer')
        if (drawer) {
            drawer.focus({ focusVisible: true })
        } else {
            setTimeout(() => {
                const drawer = document.getElementById('primary-drawer')

                if (drawer) {
                    drawer.focus({ focusVisible: true })
                }
            }, 500)
        }
    }

    const handleFocus = (e, id) => {
        e.stopPropagation()

        map.setFeatureState(
            {
                source: 'forecast',
                id,
            },
            { hover: true }
        )

        // Zoom the map way out so that the marker is in view
        if (markerRef.current && !elementIsInViewport(markerRef.current)) {
            map.easeTo({
                center: lnglat,
                zoom: 3,
                duration: 2000,
            })
        }
    }

    const handleFocusBlur = (e, id) => {
        e.stopPropagation()

        map.setFeatureState(
            {
                source: 'forecast',
                id,
            },
            { hover: false }
        )
    }

    if (!visible) {
        return null
    }

    return (
        <MapContext.Marker key={slug} lnglat={lnglat}>
            <button
                ref={markerRef}
                aria-live="polite"
                className="visually-hidden"
                onClick={e => handleMarkerClick(e, id)}
                onFocus={e => handleFocus(e, id)}
                onBlur={e => handleFocusBlur(e, id)}>
                {title}
            </button>
        </MapContext.Marker>
    )
}

export function WeatherStations({ on }) {
    const key = Products.WEATHER_STATION
    const { visible } = useLayerState(key)
    const features = Sources.useWeatherStations()
    const layer = createLayer(key, key, 'symbol')

    return (
        <MapContext.Source id={key} data={features} cluster clusterProperties={ClusterProperties}>
            <MapContext.Layer {...layer} visible={visible} on={on} />
        </MapContext.Source>
    )
}

export function SPAW({ on }) {
    const key = Products.SPAW
    const geoJSON = Sources.useSPAWArea()
    // Inject SPAW product type into the properties, needed in the map click handler
    const productInjectedGeoJSON = {
        ...geoJSON,
        features: geoJSON.features.map(feature => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    product: Products.SPAW,
                },
            }
        }),
    }

    const layers = [createLayer(key, key, 'fill'), createLayer(key + '-line', key, 'line')]

    return (
        <MapContext.Source id={key} data={productInjectedGeoJSON}>
            {layers.map(layer => (
                <MapContext.Layer key={layer.id} {...layer} on={on} />
            ))}
        </MapContext.Source>
    )
}

export function ClosureZones() {
    const key = Products.CLOSURE_ZONE
    const { visible } = useLayerState(key)
    const styles = MapStyles.get(key)

    return (
        <MapContext.VectorTileSource id={key} url={TILESETS.closureZones}>
            {Object.entries(styles).map(([type, style]) => (
                <MapContext.Layer
                    key={type}
                    id={key + '-' + type}
                    type={type}
                    visible={visible}
                    source-layer="Closure_Zones"
                    minzoom={type === 'fill' ? 6 : 8}
                    {...style}
                />
            ))}
        </MapContext.VectorTileSource>
    )
}

export function MountainConditionReports({ on }) {
    const key = Products.MOUNTAIN_CONDITIONS_REPORT
    const { visible } = useLayerState(key)
    const reports = Sources.useMountainConditionReports()
    const id = useSearchPanelId(key)
    const activeReport = Sources.useMountainConditionReport(id)
    const layer = { type: 'symbol', visible, ...MapStyles.get(key).symbol }
    const ids = [key, key + '-report']

    return (
        <React.Fragment>
            <MapContext.Source
                id={ids[0]}
                data={reports}
                cluster
                clusterProperties={ClusterProperties}>
                <MapContext.Layer id={ids[0]} {...layer} on={on} />
            </MapContext.Source>
            <MapContext.Source id={ids[1]} data={activeReport}>
                <MapContext.Layer id={ids[1]} {...layer} on={on} />
            </MapContext.Source>
        </React.Fragment>
    )
}

export function FatalAccidents({ on }) {
    const key = Products.ACCIDENT
    const { visible } = useLayerState(key)
    const features = Sources.useAccidents()
    const layer = createLayer(key, key, 'symbol')

    return (
        <MapContext.Source id={key} data={features} cluster clusterProperties={ClusterProperties}>
            <MapContext.Layer {...layer} visible={visible} on={on} />
        </MapContext.Source>
    )
}

export function MountainInformationNetwork({ on }) {
    const key = Products.MOUNTAIN_INFORMATION_NETWORK
    const activeReportKey = key + '-active-report'
    const id = useSearchPanelId(key)
    const { visible } = useLayerState(key)
    const reports = Sources.useMountainInformationNetworkReports()
    const activeReport = Sources.useMountainInformationNetworkReport(id)
    const layer = { type: 'symbol', visible, ...MapStyles.get(key).symbol, on }

    return (
        <React.Fragment>
            <MapContext.Source
                id={key}
                data={reports}
                cluster
                clusterProperties={MINClusterProperties}>
                <MapContext.Layer id={key} {...layer} />
            </MapContext.Source>
            <MapContext.Source id={activeReportKey} data={activeReport}>
                <MapContext.Layer id={activeReportKey} {...layer} />
            </MapContext.Source>
        </React.Fragment>
    )
}

export function IceClimbing({ on }) {
    const key = Products.ICE_CLIMB
    const { visible } = useLayerState(key)

    return (
        <React.Fragment>
            <MapContext.Source
                id={key}
                data={iceClimbs}
                cluster
                clusterProperties={ClusterProperties}>
                <MapContext.Layer
                    id={key}
                    type="symbol"
                    visible={visible}
                    {...MapStyles.get(key).symbol}
                    on={on}
                />
            </MapContext.Source>
        </React.Fragment>
    )
}

// Utils and constants
function createLayer(id, source, type, styles = MapStyles.get(source)[type]) {
    return { id, source, type, ...styles }
}
function useSearchPanelId(product) {
    const params = useSecondaryDrawer()

    return params.product === product ? params.id : null
}
const ClusterProperties = {
    product: ['string', ['get', 'product']],
}
const MINClusterProperties = {
    ...ClusterProperties,
    ...Object.values(ObservationTypes).reduce((properties, obtype) => {
        properties[obtype] = ['+', ['get', obtype]]

        return properties
    }, {}),
}

const elementIsInViewport = el => {
    const { top, left, bottom, right } = el.getBoundingClientRect()
    const { innerHeight, innerWidth } = window

    // Used 90% of the screen here if the polygon is on the edge of the screen I think we should still pan
    return top >= 0 && left >= 0 && bottom <= innerHeight * 0.9 && right <= innerWidth * 0.9
}
