import { isValidElement, useState } from 'react'

import { PLACENAME_SEARCH } from '@avcan/constants/products/mixpanel.js'
import { noop } from '@avcan/utils/function'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'

import { Input } from 'components/controls'
import { OptionSet } from 'components/controls/options'
import { Place } from 'components/icons'
import { PRIMARY } from 'constants/colors'
import * as Async from 'contexts/async'
import * as MapContext from 'contexts/map'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useMapGeocoder } from './useMapGeocoder.js'

import css from './MapGeocoder.module.css'

export const MapGeocoder = () => {
    const intl = useIntl()

    const map = MapContext.useMap()

    const [lngLat, setLngLat] = useState(null)

    const sendTrackEvent = useSendTrackEvent()
    const {
        term,
        hasTerm,
        terms,
        active,
        activate,
        deactivate,
        selectedIndex,
        setTerm,
        setTermCount,
        onFocus,
        onKeyDown,
    } = useMapGeocoder()

    const placeholder = intl.formatMessage({
        description: 'Geocoder',
        defaultMessage: 'Search Places…',
    })

    const handleChange = value => {
        activate()
        setTerm(value)
    }

    const handleOptionClick = place => {
        sendTrackEvent(PLACENAME_SEARCH, {
            place_name: place.name,
            typed_place_name: term,
            place_id: place.id,
        })

        const { latitude, longitude } = place

        deactivate()
        setTerm(place.name)

        setLngLat({ lng: longitude, lat: latitude })
        map.flyTo({
            center: [longitude, latitude],
            zoom: 15,
        })
    }

    const handleClearClick = () => {
        deactivate()
        setTerm('')
        setLngLat(null)
    }

    return (
        <div className={css.Container}>
            <div className={css.Control}>
                <div className={css.Icon}>
                    <Place color={PRIMARY} />
                </div>
                <Input
                    type="text"
                    className={css.Input}
                    placeholder={placeholder}
                    value={term}
                    onChange={e => handleChange(e.target.value)}
                    onKeyDown={event => onKeyDown(event, handleOptionClick)}
                    onFocus={onFocus}
                    onBlur={deactivate}
                />
            </div>
            <Async.Provider value={terms}>
                {hasTerm && (
                    <Async.Found>
                        <button className={css.Clear} onClick={handleClearClick}>
                            ×
                        </button>
                    </Async.Found>
                )}
                <Async.Found>
                    {terms => {
                        if (!hasTerm || !active) {
                            return null
                        }

                        if (terms.length === 0) {
                            return (
                                <div className={clsx(css.BoxWithShadow, css.PaddedText)}>
                                    <FormattedMessage
                                        defaultMessage="No results found"
                                        description="Map geocoder search results"
                                    />
                                </div>
                            )
                        }

                        setTermCount(terms.length)

                        return (
                            <div className={css.BoxWithShadow}>
                                <OptionSet onChange={handleOptionClick}>
                                    {terms.map((place, index) => {
                                        const selected = index === selectedIndex

                                        return (
                                            <Option
                                                key={place.id}
                                                value={place}
                                                selected={selected}>
                                                {place.name}
                                            </Option>
                                        )
                                    })}
                                </OptionSet>
                            </div>
                        )
                    }}
                </Async.Found>
            </Async.Provider>
            {lngLat && (
                <MapContext.Marker lnglat={lngLat}>
                    <Place color={PRIMARY} />
                </MapContext.Marker>
            )}
        </div>
    )
}

const Option = ({ children, value, selected, onClick = noop }) => {
    const title = isValidElement(children) ? value : children
    const name = selected ? 'Option--Selected' : 'Option'

    const handleMouseDown = () => {
        onClick(value)
    }

    return (
        <div title={title} onMouseDown={handleMouseDown} className={css[name]}>
            {children}
        </div>
    )
}
