import * as KeyCodes from '@avcan/constants/keycodes'
import { DANGER_RATINGS_GLOSSARY_OPENED } from '@avcan/constants/products/mixpanel'
import Link from 'next/link'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import Button, { Close } from 'components/button'
import Dialog, { Body, Footer, Header } from 'components/dialog'
import { Details } from 'components/error'
import { Warning } from 'components/icons'
import DangerScale from 'components/icons/DangerScale.svg'
import CreateMountainInformationNetworkReportIcon from 'components/icons/min/add-min-pin.svg'
import MountainWeatherForecastIcon from 'components/icons/weather-forecast.svg'
import Shim from 'components/Shim'
import { ERRORS, useMapState } from 'contexts/map/state'
import { useBoolean, useEventListener } from 'hooks'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import shim from 'components/Shim.module.css'
import typography from 'components/text/Text.module.css'
import styles from './Map.module.css'

export const LinkControlSet = () => {
    const sendTrackEvent = useSendTrackEvent()

    const handleClick = () => {
        sendTrackEvent(DANGER_RATINGS_GLOSSARY_OPENED, {})
    }

    return (
        <div className={styles.LinkControlSet}>
            <Link href="/weather/forecast" className={styles.LinkControl}>
                <img src={MountainWeatherForecastIcon.src} alt="Mountain Weather Forecast Icon" />
            </Link>
            <Link href="/mountain-information-network/submit" className={styles.LinkControl}>
                <img
                    src={CreateMountainInformationNetworkReportIcon.src}
                    alt="Mountain Information Network Icon"
                />
                <span className={styles.MINLink}>
                    <FormattedMessage
                        description="Layout - Main map"
                        defaultMessage="Create a{br}MIN Report"
                        values={{
                            br: <br />,
                        }}
                    />
                </span>
            </Link>
            <Link
                href="/glossary#avalanche-danger-scale"
                className={styles.LinkControl}
                onClick={handleClick}>
                <img src={DangerScale.src} alt="Danger Rating Icon" />
                <span className={styles.DangerScaleLink}>
                    <FormattedMessage
                        description="Layout - Main map"
                        defaultMessage="Avalanche{br}Danger Scale"
                        values={{
                            br: <br />,
                        }}
                    />
                </span>
            </Link>
            <ErrorIndicator />
        </div>
    )
}

const ErrorIndicator = () => {
    const [opened, open, close] = useBoolean(false)
    const state = useMapState()
    const { total } = state.errors

    if (total === 0) {
        return null
    }

    return (
        <>
            <button onClick={open} className={styles.Error}>
                <Warning inverse />
            </button>
            <ErrorDialog opened={opened} close={close} />
        </>
    )
}

function ErrorDialog({ opened, close }) {
    const intl = useIntl()
    const state = useMapState()
    const summaries = new Map(
        Object.entries(SummaryMessages).map(([key, message]) => [key, intl.formatMessage(message)])
    )
    function reload() {
        window.location.reload()
    }

    useEventListener('keyup', event => {
        if (KeyCodes.esc === event.keyCode) {
            close()
        }
    })

    return (
        <Dialog open={opened}>
            <Header>
                <FormattedMessage
                    description="Layout main/index"
                    defaultMessage="Uh oh! We never thought that would happen..."
                />
                <Close onClick={close} />
            </Header>
            <Body>
                <p>
                    <strong>
                        <FormattedMessage
                            description="Layout main/index"
                            defaultMessage="{count, plural, one {One error} other {# errors}} occurred."
                            values={{ count: state.errors.total }}
                        />
                    </strong>
                </p>
                <p>
                    <FormattedMessage
                        description="Layout main/index"
                        defaultMessage="You can still use the map. However, some data might be missing and behaviour not working as expected. Click on the arrow for more details."
                    />
                </p>
                {Array.from(state.errors.value.entries(), ([type, errors]) => (
                    <Details
                        key={type.description}
                        summary={summaries.get(type)}
                        className={shim.vertical}>
                        <ul className={typography.Initial}>
                            {Array.from(errors).map((error, index) => (
                                <li key={index}>{error.message}</li>
                            ))}
                        </ul>
                    </Details>
                ))}
            </Body>
            <Footer>
                <Shim right>
                    <Button onClick={reload}>
                        <FormattedMessage defaultMessage="Reload the map" />
                    </Button>
                </Shim>
                <Button onClick={close}>
                    <FormattedMessage defaultMessage="Close" />
                </Button>
            </Footer>
        </Dialog>
    )
}

const SummaryMessages = defineMessages({
    [ERRORS.MAP]: {
        description: 'Layout main/Map error displayed to users',
        defaultMessage: 'A problem happened while showing the map',
    },
    [ERRORS.FORECAST]: {
        description: 'Layout main/Map error displayed to users',
        defaultMessage: 'A problem happened while loading forecast on the map.',
    },
    [ERRORS.WEATHER_STATION]: {
        description: 'Layout main/Map error displayed to users',
        defaultMessage: 'A problem happened while loading Weather Station on the map.',
    },
    [ERRORS.MOUNTAIN_CONDITIONS_REPORT]: {
        description: 'Layout main/Map error displayed to users',
        defaultMessage: 'A problem happened while loading Mountain Conditions Reports on the map.',
    },
    [ERRORS.INCIDENT]: {
        description: 'Layout main/Map error displayed to users',
        defaultMessage: 'A problem happened while loading Incidents on the map.',
    },
    [ERRORS.MOUNTAIN_INFORMATION_NETWORK]: {
        description: 'Layout main/Map error displayed to users',
        defaultMessage:
            'A problem happened while loading Mountain Information Network (MIN) Reports on the map.',
    },
})
