import * as Turf from '@turf/helpers'
import * as Products from '@avcan/constants/products'

export function createMarkerFeature({ product, centroid, icons, url, owner }) {
    const { slug, title, id } = product
    const { longitude, latitude } = centroid
    const properties = {
        id,
        slug,
        product: Products.FORECAST,
        title,
        url,
        owner,
        icon: icons.find(findIcon).graphic,
    }

    return Turf.point([longitude, latitude], properties, { id })
}

export function createMarkerFeatureCollection(metadata = []) {
    return Turf.featureCollection(metadata.map(createMarkerFeature))
}

// Utils
function findIcon({ from, to }) {
    const now = new Date()

    return new Date(from) < now && now < new Date(to)
}
